import axios from 'axios';
export const SERVER_URL = "https://www.antp.org.mx";
//const SERVER_URL = "http://localhost:8000";

const fallo_token = {
    success: false,
    data:{
        status: 5
    }
}

const omitir_token = {
    success: true,
    data:{
        status: 10
    }
}

export const mail_registro_confirmacion = async (data) => {

        const ENDPOINT = `${SERVER_URL}/api/webinar/registro/1-coloquio-transporte-multimodal`;
        try {
            let response = await axios.post(ENDPOINT,data, {
                headers: {
                    //'Content-Type': 'application/json',
                    'Content-Type': 'multipart/form-data',
                }
            });
            if(response.data.success){
                return response.data;
            }else{
                return fallo_token;
            }
            
        } catch(e){
            console.log(e);
            return fallo_token.data;
        }
    
}
    export const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
        const response = await axios.post(`${SERVER_URL}/api/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        console.log('URL del archivo:', response.data.url);
        return response.data.url;
    } catch (error) {
        console.error('Error al cargar el archivo:', error);
        throw error;
    }
};
export const guardar_encuesta = async (data) => {

    const ENDPOINT = `${SERVER_URL}/encuesta-webinar`;
    try {
        let response = await axios.post(ENDPOINT,data, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if(response.data.success){
            return response.data;
        }else{
            return fallo_token;
        }
        
    } catch(e){
        console.log(e);
        return fallo_token.data;
    }

}

export const permitir_entrada = async (data) => {

    const ENDPOINT = `${SERVER_URL}/webinar/permitir-entrada`;
    try {
        let response = await axios.post(ENDPOINT,data, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if(response.data.success){
            return response.data;
        }else{
            return fallo_token;
        }
        
    } catch(e){
        console.log(e);
        return fallo_token.data;
    }

}

export const encuesta_contestada = async (evento,correo) => {

    const ENDPOINT = `${SERVER_URL}/webinar/encuesta-contestada/${evento}/${correo}`;
    try {
        let response = await axios.get(ENDPOINT, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if(response.data.success){
            return response.data;
        }else{
            return fallo_token;
        }
        
    } catch(e){
        console.log(e);
        return fallo_token.data;
    }

}

export const get_token_resgistro = async (data) => {
    const ENDPOINT = `${SERVER_URL}/webinar/buscar-usuario`;
        try {
            let response = await axios.post(ENDPOINT,data, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            return response.data;
        } catch(e){
            console.log(e);
            return fallo_token.data;
        }
}

export const responder_encuesta = async (nombre_evento, token, folio, data) => {
    const ENDPOINT = `${SERVER_URL}/api/webinar/diploma/${nombre_evento}/${token}/${folio}`;
        try {
            let response = await axios.post(ENDPOINT,data, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if(response.data.success){
                return response.data;
            }else{
                return fallo_token;
            }
            
        } catch(e){
            console.log(e);
            return fallo_token.data;
        }
}